import { globalVar } from "~/utils/globalVar"
export default {
	methods: {
		async initSocketTheme(theme = globalVar.themes.theme2.value) {
			this.socket = await this.$nuxtSocket({
				reconnection: false,
				auth: {
					Authorization: `Bearer ${this.$cookie.get('token')}`,
				},
				transports: ['websocket'],
			})
			this.socket.on('disconnect', (e) => {
				this.socket.connect()
			})

			// Backend team need this callback
			this.socket.on('ws-ping', (payload, callback) => {
				try {
					// eslint-disable-next-line n/no-callback-literal
					callback('ykx52280')
				} catch (_) { }
			})


			let reconnectCount = 0
			this.socket.on('connect_error', (e) => {
				//  in case token is expired
				if (typeof e === 'object') {
					const message = e?.message
					try {
						const messageObject = JSON.parse(message)
						if (messageObject?.code === 401) {
							this.logout()
						}
					} catch (_) { }
				}
				const MAX_RECONNECTION_FAIL_COUNT = 1000
				const TIME_INTERVAL_TO_RECONNECT = 1000

				if (reconnectCount < MAX_RECONNECTION_FAIL_COUNT) {
					setTimeout(() => {
						this.socket.connect()
					}, TIME_INTERVAL_TO_RECONNECT)
					reconnectCount++
				}
			})

			this.socket.on('playerBalance', (msg) => {
				this.$store.dispatch('player/totalBalanceSocket', msg)
			})
			this.socket.on('revokeWithdrawal', async (msg) => {
				if (theme === globalVar.themes.theme3.value) {
					this.$store.commit('sockets/setWithdrawalStatus', false)
					this.$store.commit('sockets/setDisplayWithdrawalSocket', true)
				}
				if (!this.$root?.withdrawExistChange) return
				const result = await this.$store.dispatch(
					'themes/withdraw/checkExistWithdrawal'
				)
				this.$root.withdrawExistChange(result)
			})
			this.socket.on('lockWithdrawal', async (msg) => {
				if (!this.$root?.withdrawExistChange) return
				const result = await this.$store.dispatch(
					'themes/withdraw/checkExistWithdrawal'
				)
				this.$root.withdrawExistChange(result)
			})
			this.socket.on('unlockWithdrawal', async (msg) => {
				if (!this.$root?.withdrawExistChange) return
				const result = await this.$store.dispatch(
					'themes/withdraw/checkExistWithdrawal'
				)
				this.$root.withdrawExistChange(result)
			})
			this.socket.on('successfulWithdrawal', async (msg) => {
				if (theme === globalVar.themes.theme3.value) {
					this.$store.commit('sockets/setWithdrawalStatus', true)
					this.$store.commit('sockets/setDisplayWithdrawalSocket', true)
				}
				if (!this.$root?.withdrawExistChange) return
				const result = await this.$store.dispatch(
					'themes/withdraw/checkExistWithdrawal'
				)
				this.$root.withdrawExistChange(result)
			})

			this.socket.on('onHoldPlayerBalance', (msg) => {
				this.$store.dispatch('player/setHoldBalance', msg.balance)
				this.$store.commit('sockets/setShowHoldBalance', true)
			})
			this.socket.on('successfulDeposit', (msg) => {
				this.$store.commit('sockets/setDisplayGetBonusDetail', msg)
				this.$store.commit('sockets/setDisplayGetBonusModal', true)
			})

			// Subscribe Store, Socket Only
			this.$store.subscribeAction((mutation, state) => {
				// Confirm Deposit
				if (
					mutation.type === 'sockets/revokeWithdrawal' ||
					mutation.type === 'sockets/successfulWithdrawal' ||
					mutation.type === 'sockets/revokeDepositByAdmin' ||
					mutation.type === 'sockets/successfulDeposit'
				) {
					if (!this.$root?.isTransactionHistoryPage) return
					this.$store.dispatch('themes/transaction/getTransactions', {
						sort: 'created_at|desc',
						limit: 10,
						page: 1,
						language: this.language,
						start_date: '2022-01-01 00:00:00',
						end_date: this.$moment()
							.local()
							.format('YYYY-MM-DD HH:mm:ss'),
					})
				}
			})
		}
	}
}