import { load } from '@fingerprintjs/fingerprintjs'

export default {
	methods: {
		async visitorId() {
			const fingerPrint = await load()
			const visitorId = await (await fingerPrint.get()).visitorId
			return visitorId
		}
	},
}
