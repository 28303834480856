export default async ({ app, req, $cookie }, inject) => {
	if (process.client) {
		try {
			await app.store.dispatch('settings/getDefaultCurrency')
			/** In the API,
				* set currency by the API currency into cookies
				* commit setDefaultCurrency
				* set defaultCountry into cookies
				*/
			if ($cookie?.get('currency')) {
				// if The Player is not logged in yet, call the api
				// if Player is already logged in, use player base currency
				if ($cookie?.get('currency')) app.store.commit('settings/setDefaultCurrency', $cookie?.get('currency'))
				if ($cookie?.get('country')) app.store.commit('settings/setDefaultCountry', $cookie?.get('country'))
			}
		} catch (error) {
		}
	}
}