export default {
	computed: {
		player() {
			return this.$store.state.player.player
		}
	},
	methods: {
		onChangeLanguage(code, language, currency) {
			// this.$store.commit("settings/changeLanguageLoading", true)
			// change recaptcha language
			this.$cookie.set("is_change_language", true)
			const languageCodeForRecaptcha = language?.substr(0, 2)
			if (languageCodeForRecaptcha) {
				this.$recaptcha.language = languageCodeForRecaptcha
			}
			const countryCode = this.$cookie.get('country_code');
			// ? check if the selected language is the old one, stop making request to server
			if (this.systemLocale === language && countryCode === code) return
			this.$cookie.set('country_code', code)
			this.$cookie.set('locale', language)
			if (this.$cookie?.get('currency') !== currency) {
				this.$cookie.set('currency', currency)
			}
			// this.$store.dispatch('settings/locale', language)
			if (this.$cookie?.get('token')) {
				const form = { language }
				this.$cookie.set('locale', language, {
					path: '/',
					maxAge: 60 * 60 * 3 - 60,
				})
				this.$store.dispatch('player/update', form)
			}
			window.location.reload();
		},
		async setDefaultLanguages() {
			let params = null
			// in case player already login, use player base currency to fetch all languages
			if (this.player.currency) params = this.player.currency
			else if (this.$cookie.get('currency'))
				params = this.$cookie.get('currency')

			await this.$store.dispatch('themes/settings/getLanguage', params)
		}
	},
}
